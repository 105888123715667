import axios from 'axios'
import Vue from 'vue'
import dicomweb from './dicomweb'
import store from '../store'

const WORKLIST_NAME_FOR_PAUSE = '__WoRkLiSt_Is_PaUsEd__'

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function getEmptyWorklistEntry() {
    return {
        study_uid: '',
        assigned_to: '',
        referring_physician: '',
        patient_name_dcm: '',
        patient_name: '',
        patient_age: '',
        dob_dcm: '',
        dob: '',
        sex: '',
        patient_id: '',
        issuer: '',
        acc_num: '',
        study_id: '',
        modality: '',
        study_date_dcm: '',
        study_time_dcm: '',
        study_date_time: '',
        rcvd_date_time: '',
        images: '',
        num_images: 0,
        num_series: 0,
        study_desc: '',
        institution: '',
        reading_physician: '',
        tat: '',
        oo: false,
        order_priority: '---',
        order_status: '---',
        report_status: '---',
        study_status: '---',
        lock: '',
        lufn: '',
        patient_location: '',
        patient_address: '',
        patient_email: '',
        patient_telephone: '',
        addendum_reports: '',
        group: '',
        _cas: false,
        _cds: false,
        _cep: false,
        _cms: false,
        _crs: false,
        _css: false,
        _cust: false,
        _cus2: false,
        _cvp: false,
        _cvi: false,
        _cvr: false,
        n2r: 30,
        phd: false,
        phn: false,
        read: false,
        stat: false,
        d: 'Unread',
        _rowVariant: 'secondary'
    }
}

function getTitleForEntry(entry) {
    const locale = store.state.locale
    let ww = (window.outerWidth > window.innerWidth) ? window.innerWidth : window.outerWidth
    var title = entry.patient_name 
    if (ww >= 600) {
        title += ' (MRN: '+entry.patient_id+') (Tenant: '+entry.group+') ('
    }
    else {
        title += ' ('+entry.patient_id+') ('
    }
    title += (entry.study_date_time == null) ? '---' : new Date(entry.study_date_time).toLocaleString(locale)
    title += ')'
    return title
}

// Lookup variant for report status
//
function reportStatusToVariant(status, read, stat) {
    var variant = 'warning'
    switch(status) {
        case 'DRAFT':
        case 'AMENDED DRAFT':
            if (read) {
                variant = 'primary'
            }
            else {
                variant = 'secondary'
            }
            break;
        case 'AMENDED PRELIMINARY':
        case 'PRELIMINARY':
            variant = 'primary'
            break;
        case 'AMENDED FINAL':
        case 'FINAL':
            variant = 'success'
            break;
        default:
            if (read) {
                variant = 'primary'
            }
            else if (stat) {
                variant = 'danger'
            }
            break;
    }
    return variant
}

// Return diagnostic for item.
// NOTE: must match logic in web_services.
//
function diagnostic(item) {
  var diagnostic = 'Unread'
  if (item.oo) {
    // Order Only
    if (item.read) {
      diagnostic = 'Read'
    }
    else {
      switch(item.order_status) {
        case 'COMPLETE':
          diagnostic = 'In Transit'
          break
        case 'CANCELED':
          diagnostic = 'Canceled'
          break
        default:
          diagnostic = 'Scheduled'
          break
      }
    }
  }
  else {
    if (item._rowVariant == 'light') {
      diagnostic = 'In Transit'
    }
    switch(item.report_status) {
        case 'DRAFT':
        case 'AMENDED DRAFT':
            if (item.read) {
                diagnostic = 'Read'
            }
            else {
                diagnostic = 'Unverified'
            }
            break;
        case 'AMENDED PRELIMINARY':
        case 'PRELIMINARY':
            diagnostic = 'Read'
            break;
        case 'AMENDED FINAL':
        case 'FINAL':
            diagnostic = 'Final'
            break;
        default:
            if (item.read) {
                diagnostic = 'Read'
            }
            break;
    }
  }
  return diagnostic
}

// Parse ISO timestamp to MM/DD/YYYY HH:MM:SS format.
//
function parseIsoTimestamp(timestamp) {
    try {
        let rd = new Date(timestamp)
        return rd.toLocaleString(store.state.locale)
    }
    catch(err) {
        Vue.$log.error('Unable to parse ISO timestamp=['+timestamp+']: '+err.message)
    }
    return ''
}

// Parse DICOM date to MM/DD/YYYY format.
//
function parseDA(daValue)
{
    var parsedValue = ''
    if (daValue !== '')
    {
        try {
            parsedValue = daValue.substring(4,6)+'/'+daValue.substring(6)+'/'+daValue.substring(0,4)
        }
        catch(err) {
            parsedValue = daValue
        }

    }
    return parsedValue
}

// Parse DICOM time to hh:mm:ss formaat.
//
function parseTM(tmValue)
{
    var parsedValue = ''
    if (tmValue !== '')
    {
        try {
            parsedValue = tmValue.substring(0,2)+':'+tmValue.substring(2,4)+':'+tmValue.substring(4,6)
        }
        catch(err) {
            parsedValue = tmValue
        }

    }
    return parsedValue
}

// Parse DICOM patient name to "<last_name>, <first_name> <middle_name>" format.
//
function parsePN(pnValue)
{
    var parsedValue = ''
    if (pnValue !== '')
    {
        try {
            var parsedArray = pnValue['Alphabetic'].toUpperCase().split('^');
            parsedValue = parsedArray[0]; // last name
            if (parsedArray.length > 1) {
                parsedValue += ', ' + parsedArray[1] // first name
                if (parsedArray.length > 2) {
                    parsedValue += ' ' + parsedArray[2] // middle name
                }
            }
        }
        catch(err) {
            parsedValue = ''
        }

    }
    return parsedValue
}

// Gets worklist from web services endpoint.
//
var readWorklistTimerId = null
function readWorklist() {
    if ((Object.keys(store.state.worklistSettings).length > 0) && 
        (store.state.worklistRefreshing != store.state.worklistName) &&
        (store.state.worklistName != '') && 
        (store.state.worklistName != WORKLIST_NAME_FOR_PAUSE)) {
        if (readWorklistTimerId != null) {
            clearTimeout(readWorklistTimerId)
            readWorklistTimerId = null
        }
        const filterName = store.state.worklistName
        store.commit('changeWorklistRefreshing', filterName)
        const customerId = store.state.customerId
        axios.get(`/Worklist/${encodeURIComponent(customerId)}/${encodeURIComponent(filterName)}`, {
            baseURL: store.state.webServicesBaseUrl,
            headers: {
                Authorization: 'Bearer '+store.state.keycloak.token
            }
        })
        .then(response => {
            Vue.$log.debug('received worklist for ['+filterName+']')
            if (filterName == store.state.worklistName) {
                store.commit("changeWorklist", response.data)
                store.commit("changeWorklistRefreshFlag", true)
            }
            else {
                Vue.$log.debug("ignoring stale query results for ["+filterName+"]")
            }
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            store.commit("changeWorklistRefreshFlag", false)
        })
        .finally (() => {
            store.commit('changeWorklistRefreshing', '')

            // Set up auto-refresh of worklist
            //
            readWorklistTimerId = setTimeout(() => {
                Vue.$log.debug('auto refreshing worklist')
                readWorklistTimerId = null
                readWorklist()
            }, store.state.worklistRefreshInterval * 60000);
        })
    }
    else {
        Vue.$log.debug("Ignoring request to refresh worklist, worklistName=["+store.state.worklistName+"]")
    }
    return
}

function stopWorklistRefresh() {
    if (store.state.worklistName != WORKLIST_NAME_FOR_PAUSE) {
        store.commit('changeCachedWorklistName', store.state.worklistName)
        store.commit('changeWorklistName', WORKLIST_NAME_FOR_PAUSE)
        readWorklistTimerId = null
    }
}

function restartWorklistRefresh() {
    if (store.state.worklistName == WORKLIST_NAME_FOR_PAUSE) {
        store.commit('changeWorklistName', store.state.cachedWorklistName)
        store.commit('changeCachedWorklistName', '')
    }
}

function readSingleStudyEntry(group, studyUid, studyDate, accNum = '') {
    return new Promise((resolve, reject) => {
        const filterName = '__SiNgLe_StUdY__'
        const groupId = group.replace(/\//g, '___').replace(/ /g, '_').toUpperCase()
        const customerId = store.state.customerId
        store.commit('changeWorklistRefreshing', true)
        const worklistUrl = store.state.webServicesBaseUrl +
            `/Worklist/${encodeURIComponent(customerId)}/${encodeURIComponent(filterName)}` +
            '?groupId=' + encodeURIComponent(groupId) +
            '&accNum=' + encodeURIComponent(accNum) +
            '&studyUid=' + encodeURIComponent(studyUid) +
            '&studyDate=' + encodeURIComponent(studyDate)
        axios.get(worklistUrl, {
            headers: {
                Authorization: 'Bearer '+store.state.keycloak.token
            }
        })
        .then(response => {
            Vue.$log.debug('returing entry from worklist for ['+filterName+']')
            resolve(response[0])
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

function readSingleStudyWorklist(groupId, studyUid, studyDate, accNum = '') {
    stopWorklistRefresh()

    return new Promise((resolve, reject) => {
        const filterName = '__SiNgLe_StUdY__'
        const customerId = store.state.customerId
        store.commit('changeWorklistRefreshing', true)
        const worklistUrl = store.state.webServicesBaseUrl +
            `/Worklist/${encodeURIComponent(customerId)}/${encodeURIComponent(filterName)}` +
            '?groupId=' + encodeURIComponent(groupId) +
            '&accNum=' + encodeURIComponent(accNum) +
            '&studyUid=' + encodeURIComponent(studyUid) +
            '&studyDate=' + encodeURIComponent(studyDate)
        axios.get(worklistUrl, {
            headers: {
                Authorization: 'Bearer '+store.state.keycloak.token
            }
        })
        .then(response => {
            Vue.$log.debug('received worklist for ['+filterName+']')
            store.commit("changeWorklist", response.data)
            resolve('')
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
        .finally (() => {
            store.commit('changeWorklistRefreshing', false)
        })
    })
}

// Find all studies associated for patient in provided worklist entry
//
function readSinglePatientWorklist(worklistEntry) {
    return new Promise((resolve, reject) => {
        const filterName = '__SiNgLe_PaTiEnT__'
        const customerId = store.state.customerId
        const worklistUrl = store.state.webServicesBaseUrl +
            `/Worklist/${encodeURIComponent(customerId)}/${encodeURIComponent(filterName)}` +
            '?group=' + encodeURIComponent(worklistEntry.group) +
            '&patientId=' + encodeURIComponent(worklistEntry.patient_id)
        axios.get(worklistUrl, {
            headers: {
                Authorization: 'Bearer '+store.state.keycloak.token
            }
        })
        .then(response => {
            Vue.$log.debug('received worklist for ['+filterName+'] (not for worklist in store)')
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Returns entire URL for retrieving a report.
//
function reportUrl(studyUid, reportId, group, format) {
    var endpointUrl = store.state.webServicesBaseUrl + 
        '/Report/' + encodeURIComponent(studyUid) +
        '/' + encodeURIComponent(reportId) +
        '?group=' + encodeURIComponent(group)
    if (format != '') {
        endpointUrl += '&format=' + encodeURIComponent(format)
    }   
    return endpointUrl
}

function signatureUrl() {
    return `${store.state.webServicesBaseUrl}/UserSettings/signature/${encodeURIComponent(store.state.customerId)}`
}

// Connects to web services to fetch AE Title for group/tenant
//
function readGroupAeTitle(groupName) {
    return new Promise((resolve, reject) => {
        axios.get('/GroupSettings/' + encodeURIComponent(groupName) , {
            baseURL: store.state.webServicesBaseUrl,
            headers: {
                Authorization: 'Bearer '+store.state.keycloak.token
            }
        }).then(response => {
            Vue.$log.debug('response.data for ['+groupName+']=['+response.data+']')
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to fetch users in group/tenant
//
function readGroupUsers(groupName) {
    return new Promise((resolve, reject) => {
        axios.get('/GroupUsers/' + encodeURIComponent(groupName) , {
            baseURL: store.state.webServicesBaseUrl,
            headers: {
                Authorization: 'Bearer '+store.state.keycloak.token
            }
        }).then(response => {
            Vue.$log.debug('response.data for ['+groupName+']=['+response.data+']')
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to fetch AE Titles of destination routes for group/tenant
//
function readGroupRouteAets(groupName) {
    return new Promise((resolve, reject) => {
        axios.get('/GroupRouteAets/' + encodeURIComponent(groupName) , {
            baseURL: store.state.webServicesBaseUrl,
            headers: {
                Authorization: 'Bearer '+store.state.keycloak.token
            }
        }).then(response => {
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to fetch list of report templates available to user.
//
function readReportTemplateList(group = null) {
    return new Promise((resolve, reject) => {
        var endpointUrl = `/ReportTemplates/${store.state.customerId}`
        if (group != null) {
            endpointUrl += `?group=${encodeURIComponent(group)}`
        }
        axios.get(endpointUrl, {
            baseURL: store.state.webServicesBaseUrl,
            headers: {
                Authorization: 'Bearer '+store.state.keycloak.token
            }
        }).then(response => {
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to fetch report as arraybuffer.
//
function readReportTemplateForUser(templateName, format) {
    return new Promise((resolve, reject) => {
        axios.get('/ReportTemplates/user/' + encodeURIComponent(templateName) + '?format=' + encodeURIComponent(format), {
            baseURL: store.state.webServicesBaseUrl,
            headers: {
                Authorization: 'Bearer '+store.state.keycloak.token
            },
            responseType: 'arraybuffer'
        }).then(response => {
            Vue.$log.debug("read template for ["+templateName+"]")
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to create template.
//
function createReportTemplateForUser(templateDocx, templateName) {
    return new Promise((resolve, reject) => {
        let formData = new FormData()
        formData.append('templateDocx', templateDocx, 'template.docx')
        axios.post('/ReportTemplates/' + encodeURIComponent(templateName),
            formData,
            {
                baseURL: store.state.webServicesBaseUrl,
                headers: {
                    'Authorization': 'Bearer '+store.state.keycloak.token,
                    'Content-Type': 'multipart/form-data'
                }
            })
        .then(response => {
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to update template.
//
function updateReportTemplateForUser(templateDocx, templateName) {
    return new Promise((resolve, reject) => {
        let formData = new FormData()
        formData.append('templateDocx', templateDocx, 'template.docx')
        axios.put('/ReportTemplates/' + encodeURIComponent(templateName),
            formData,
            {
                baseURL: store.state.webServicesBaseUrl,
                headers: {
                    'Authorization': 'Bearer '+store.state.keycloak.token,
                    'Content-Type': 'multipart/form-data'
                }
            })
        .then(response => {
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to fetch report as arraybuffer.
//
function readReportTemplateForGroup(templateName, groupName, format) {
    return new Promise((resolve, reject) => {
        axios.get('/ReportTemplates/' + encodeURIComponent(groupName) + '/' + encodeURIComponent(templateName) + '?format=' + encodeURIComponent(format), {
            baseURL: store.state.webServicesBaseUrl,
            headers: {
                Authorization: 'Bearer '+store.state.keycloak.token
            },
            responseType: 'arraybuffer'
        }).then(response => {
            Vue.$log.debug("read template for ["+templateName+"]")
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to create template.
//
function createReportTemplateForGroup(templateDocx, groupName, templateName) {
    return new Promise((resolve, reject) => {
        let formData = new FormData()
        formData.append('templateDocx', templateDocx, 'template.docx')
        axios.post('/ReportTemplates/' + encodeURIComponent(groupName) + '/' + encodeURIComponent(templateName),
            formData,
            {
                baseURL: store.state.webServicesBaseUrl,
                headers: {
                    'Authorization': 'Bearer '+store.state.keycloak.token,
                    'Content-Type': 'multipart/form-data'
                }
            })
        .then(response => {
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to update template.
//
function updateReportTemplateForGroup(templateDocx, groupName, templateName) {
    return new Promise((resolve, reject) => {
        let formData = new FormData()
        formData.append('templateDocx', templateDocx, 'template.docx')
        axios.put('/ReportTemplates/' + encodeURIComponent(groupName) + '/' + encodeURIComponent(templateName),
            formData,
            {
                baseURL: store.state.webServicesBaseUrl,
                headers: {
                    'Authorization': 'Bearer '+store.state.keycloak.token,
                    'Content-Type': 'multipart/form-data'
                }
            })
        .then(response => {
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to create report.
//
function createReport(templateSection, templateName, worklistEntry, replaceReportId) {
    return new Promise((resolve, reject) => {
        var data = {
            template_section: templateSection,
            template_name: templateName,
            replace_existing: replaceReportId
        }
        let keys = Object.keys(worklistEntry)
        for (var i=0; i < keys.length; i++) {
            let value = worklistEntry[keys[i]]
            data[keys[i]] = (value == null) ? '' : value
        }
        // +TODO+ send user's locale if need different formatting than server's locale...
        //data['locale'] = 'TBD'
        axios.post(store.state.webServicesBaseUrl+'/Report', data, 
        {
            headers: {
                Authorization: 'Bearer '+store.state.keycloak.token
            }
        })
        .then(response => {
            Vue.$log.debug("initial report created for ["+worklistEntry.study_uid+"] from template=["+templateName+"]")
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to fetch list of reports available for a study.
//
function readReportList(worklistEntry) {
    var endpointUrl = store.state.webServicesBaseUrl + 
        '/Report/' + encodeURIComponent(worklistEntry.study_uid) +
        '?group=' + encodeURIComponent(worklistEntry.group) +
        '&patient_id=' + encodeURIComponent(worklistEntry.patient_id) +
        '&acc_num=' + encodeURIComponent(worklistEntry.acc_num)
    return new Promise((resolve, reject) => {
        axios.get(endpointUrl, {
            headers: {
                Authorization: 'Bearer '+store.state.keycloak.token
            }
        }).then(response => {
            Vue.$log.debug("read report list for ["+worklistEntry.study_uid+"]")
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to fetch report as arraybuffer.
//
function readReport(studyUid, reportId, group, format) {
    return new Promise((resolve, reject) => {
        axios.get(this.reportUrl(studyUid, reportId, group, format), {
            headers: {
                Authorization: 'Bearer '+store.state.keycloak.token
            },
            responseType: 'arraybuffer'
        }).then(response => {
            Vue.$log.debug("read report for ["+studyUid+"]")
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to fetch report as arraybuffer.
//
function readOruReport(worklistEntry, reportId) {
    return new Promise((resolve, reject) => {
        var endpointUrl = store.state.webServicesBaseUrl + 
            '/OruReport/' + encodeURIComponent(worklistEntry.study_uid) +
            '/' + encodeURIComponent(reportId) +
            '?group=' + encodeURIComponent(worklistEntry.group)
        axios.get(endpointUrl, {
            headers: {
                Authorization: 'Bearer '+store.state.keycloak.token
            },
            responseType: 'arraybuffer'
        }).then(response => {
            Vue.$log.debug("read report for ["+worklistEntry.study_uid+"]")
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to update report.
//
function updateReport(reportSftp, reportDocx, entry, reportId, status, auto) {
    return new Promise((resolve, reject) => {
        let formData = new FormData()
        formData.append('status', status)
        formData.append('auto', auto ? 1 : 0)
        formData.append('customerId', store.state.customerId)
        formData.append('patient_id', entry.patient_id)
        formData.append('issuer_of_patient_id', entry.issuer)
        formData.append('reportDocx', reportDocx, 'report.docx')
        formData.append('reportSfdt', reportSftp, 'report.sfdt')
        axios.put(
            reportUrl(entry.study_uid, reportId, entry.group, ''), 
            formData,
            {
                headers: {
                    'Authorization': 'Bearer '+store.state.keycloak.token,
                    'Content-Type': 'multipart/form-data'
                }
            })
        .then(response => {
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to fetch study assignment for a group.
//
function readOrder(worklistEntry) {
    var endpointUrl = store.state.webServicesBaseUrl + 
        '/Order/' + encodeURIComponent(worklistEntry.patient_id) + 
        '/' + encodeURIComponent(worklistEntry.acc_num) +
        '?group=' + encodeURIComponent(worklistEntry.group)
    return new Promise((resolve, reject) => {
        axios.get(endpointUrl, {
            headers: {
                Authorization: 'Bearer '+store.state.keycloak.token
            }
        }).then(response => {
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to fetch list of key objects.
//
function readKeyObjects(group, studyUid) {
    var endpointUrl = store.state.webServicesBaseUrl + 
        '/KeyObjects/' + encodeURIComponent(studyUid) + '?group=' + encodeURIComponent(group)
    return new Promise((resolve, reject) => {
        axios.get(endpointUrl, {
            headers: {
                Authorization: 'Bearer '+store.state.keycloak.token
            }
        }).then(response => {
            Vue.$log.debug("read key objects for group=["+group+"] studyUid=["+studyUid+"]")
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to fetch study assignment for a group.
//
function readStudyAssignments(group, studyUid) {
    var endpointUrl = store.state.webServicesBaseUrl + 
        '/StudyAssignments/' + encodeURIComponent(studyUid) + '?group=' + encodeURIComponent(group)
    return new Promise((resolve, reject) => {
        axios.get(endpointUrl, {
            headers: {
                Authorization: 'Bearer '+store.state.keycloak.token
            }
        }).then(response => {
            Vue.$log.debug("read study assignments for group=["+group+"] studyUid=["+studyUid+"]")
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to update study assignment.
//
function updateStudyAssignment(group, studyUid, users) {
    var endpointUrl = store.state.webServicesBaseUrl + 
        '/StudyAssignments/' + encodeURIComponent(studyUid)
    var data = {
        group: group,
        users: users
    }
    return new Promise((resolve, reject) => {
        axios.put(endpointUrl, data, {
            headers: {
                Authorization: 'Bearer '+store.state.keycloak.token
            }
        }).then(response => {
            Vue.$log.debug("update study assignment for group=["+group+"] studyUid=["+studyUid+"]")
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to update study with another object
//
function createStudyObject(worklistEntry, object, filename, progressCallback) {
    return new Promise((resolve, reject) => {
        let endpoint = '/StudyObjects/' + encodeURIComponent(worklistEntry.study_uid)
        let formData = new FormData()
        formData.append('group', worklistEntry.group)
        formData.append('worklistEntry', JSON.stringify(worklistEntry))
        formData.append('object', object, filename)
        axios.put(
            endpoint,
            formData,
            {
                baseURL: store.state.webServicesBaseUrl,
                headers: {
                    Authorization: 'Bearer '+store.state.keycloak.token,
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: progressEvent => {
                    progressCallback(progressEvent)
                }
            }
        )
        .then(response => {
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to transfer study to a different group.
//
function updateStudyGroup(group, updateGroup, worklistEntry) {
    var endpointUrl = store.state.webServicesBaseUrl + 
        '/StudyGroup/' + encodeURIComponent(worklistEntry.study_uid)
    var data = {
        group: group,
        updateGroup: updateGroup,
        patient_id: worklistEntry.patient_id,
        issuer: worklistEntry.issuer
    }
    return new Promise((resolve, reject) => {
        axios.put(endpointUrl, data, {
            headers: {
                Authorization: 'Bearer '+store.state.keycloak.token
            }
        }).then(response => {
            Vue.$log.debug("transfer study from group=["+group+"] to group=["+updateGroup+"] studyUid=["+worklistEntry.study_uid+"]")
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// state in ['lock', 'unlock', 'unlock_force']
//
function updateStudyLock(worklistEntry, state) {
    var endpointUrl = store.state.webServicesBaseUrl + 
        '/StudyLock/' + encodeURIComponent(worklistEntry.study_uid)
    var data = {
        group: worklistEntry.group,
        read: worklistEntry.read ? "1" : "0",
        stat: worklistEntry.stat ? "1" : "0",
        state: state
    }
    return new Promise((resolve, reject) => {
        axios.put(endpointUrl, data, {
            headers: {
                Authorization: 'Bearer '+store.state.keycloak.token
            }
        }).then(response => {
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// state in ['read', 'unread']
//
function updateStudyRead(worklistEntry, state) {
    var endpointUrl = store.state.webServicesBaseUrl + 
        '/StudyRead/' + encodeURIComponent(worklistEntry.study_uid)
    var data = {
        group: worklistEntry.group,
        state: state
    }
    return new Promise((resolve, reject) => {
        axios.put(endpointUrl, data, {
            headers: {
                Authorization: 'Bearer '+store.state.keycloak.token
            }
        }).then(response => {
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// state in ['stat', 'normal']
//
function updateStudyStat(worklistEntry, state) {
    var endpointUrl = store.state.webServicesBaseUrl + 
        '/StudyStat/' + encodeURIComponent(worklistEntry.study_uid)
    var data = {
        group: worklistEntry.group,
        state: state
    }
    return new Promise((resolve, reject) => {
        axios.put(endpointUrl, data, {
            headers: {
                Authorization: 'Bearer '+store.state.keycloak.token
            }
        }).then(response => {
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to update patient history
//
function importStudyObject(group, object, filename, patientStudyInfo, progressCallback) {
    return new Promise((resolve, reject) => {
        let endpoint = '/StudyObjects'
        let formData = new FormData()
        formData.append('group', group)
        formData.append('patientStudyInfo', JSON.stringify(patientStudyInfo))
        formData.append('object', object, filename)
        axios.put(
            endpoint,
            formData,
            {
                baseURL: store.state.webServicesBaseUrl,
                headers: {
                    Authorization: 'Bearer '+store.state.keycloak.token,
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: progressEvent => {
                    progressCallback(progressEvent)
                }
            }
        )
        .then(response => {
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to update user's view histroy.
//
function updateStudyUserHistory(entry, type) {
    var endpointUrl = store.state.webServicesBaseUrl + 
        '/UserHistory/' + encodeURIComponent(entry.study_uid)
    var data = {
        group: entry.group,
        type: type
    }
    return new Promise((resolve, reject) => {
        axios.put(endpointUrl, data, {
            headers: {
                Authorization: 'Bearer '+store.state.keycloak.token
            }
        }).then(response => {
            Vue.$log.debug("update study user history for group=["+entry.group+"] studyUid=["+entry.study_uid+"] type=["+type+"]")
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

function updateStudyUserHistoryNoPromise(entry, type) {
    updateStudyUserHistory(entry, type)
    .then(response => {
      Vue.$log.debug("Updated user history for viewed images: "+JSON.stringify(response)) 
    })
    .catch(err => {
      Vue.$log.debug("Error updating user history for viewed images: "+err.message) 
    })
}

// Connects to web services to fetch signature as arraybuffer.
//
function readSignature() {
    return new Promise((resolve, reject) => {
        axios.get(this.signatureUrl(), {
            headers: {
                Authorization: 'Bearer '+store.state.keycloak.token
            },
            responseType: 'arraybuffer'
        }).then(response => {
            Vue.$log.debug("read signature for current user")
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to update user's signature image file
//
function updateSignature(signatureImage, filename) {
    return new Promise((resolve, reject) => {
        let formData = new FormData()
        formData.append('signatureImage', signatureImage, filename)
        axios.put(
            signatureUrl(), 
            formData,
            {
                headers: {
                    'Authorization': 'Bearer '+store.state.keycloak.token,
                    'Content-Type': 'multipart/form-data'
                }
            })
        .then(response => {
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to fetch user global settings as JSON object
//
function readUserGlobalSettings() {
    return new Promise((resolve, reject) => {
        const endpoint = `/UserSettings/global`
        axios.get(endpoint, {
            baseURL: store.state.webServicesBaseUrl,
            headers: {
                Authorization: 'Bearer '+store.state.keycloak.token
            }
        }).then(response => {
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to fetch user global settings as JSON object
//
function updateUserGlobalSettings(settings) {
    return new Promise((resolve, reject) => {
        const endpoint = `/UserSettings/global`
        axios.put(endpoint, settings, {
            baseURL: store.state.webServicesBaseUrl,
            headers: {
                Authorization: 'Bearer '+store.state.keycloak.token
            }
        }).then(response => {
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to fetch user setting as JSON object
//
function readUserSetting(key) {
    return new Promise((resolve, reject) => {
        const endpoint = `/UserSettings/setting/${encodeURIComponent(store.state.customerId)}/${encodeURIComponent(key)}`
        axios.get(endpoint, {
            baseURL: store.state.webServicesBaseUrl,
            headers: {
                Authorization: 'Bearer '+store.state.keycloak.token
            }
        }).then(response => {
            const value = response.data[key]
            resolve(value)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to update user setting as JSON object
//
function updateUserSetting(key, value) {
    var data = {}
    data[key] = value
    return new Promise((resolve, reject) => {
        const endpoint = `/UserSettings/setting/${encodeURIComponent(store.state.customerId)}/${encodeURIComponent(key)}`
        axios.put(
            endpoint, 
            data,
            {
                baseURL: store.state.webServicesBaseUrl,
                headers: {
                    Authorization: 'Bearer '+store.state.keycloak.token
                }
            })
        .then(response => {
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to update patient demographics using IOCM-RS
//
function updatePatientInfo(worklistEntry, updatedEntry) {
    return new Promise((resolve, reject) => {
        const aet = store.getters.aetForGroup('/'+worklistEntry['group'])
        axios.put(
            '/UpdateDemographics/'+aet+'/'+worklistEntry.study_uid,
            updatedEntry,
            {
                baseURL: store.state.webServicesBaseUrl,
                headers: {
                    Authorization: 'Bearer '+store.state.keycloak.token
                }
            }
        )
        .then(response => {
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Get history for a user.
//
function readUserHistory() {
    return new Promise((resolve, reject) => {
        var history = {
            reportItems: [],
            studyItems: []
        }
        var allPromises = []
        var tenants = []
        try {
          if (store.state.customerId != '') {
            const tenantsForCurrentCustomer = store.state.customers[store.state.customerId].tenants
            const tenantIds = Object.keys(tenantsForCurrentCustomer)
            tenantIds.forEach(tenantId => {
              tenants.push(tenantsForCurrentCustomer[tenantId].path)
            })
          }
        }
        catch {
          tenants = []
        }
        for (var g=0; g < tenants.length; g++) {
            let queryReportsPromise = axios.get('/UserHistory/reports/'+encodeURIComponent(tenants[g]), {
                baseURL: store.state.webServicesBaseUrl,
                headers: {
                    Authorization: 'Bearer '+store.state.keycloak.token
                }
            })
            allPromises.push(queryReportsPromise)
            queryReportsPromise
            .then(response => {
                history.reportItems.push(...response.data['reportHistory'])
            })
            .catch(err => {
                Vue.$log.warn(`webServices::readUserHistory queryReportsPromise error: ${err.message}`)
            })

            let queryStudiesPromise = axios.get('/UserHistory/studies/'+encodeURIComponent(tenants[g]), {
                baseURL: store.state.webServicesBaseUrl,
                headers: {
                    Authorization: 'Bearer '+store.state.keycloak.token
                }
            })
            allPromises.push(queryStudiesPromise)
            queryStudiesPromise
            .then(response => {
                history.studyItems.push(...response.data['studyHistory'])
            })
            .catch(err => {
                Vue.$log.warn(`webServices::readUserHistory queryStudiesPromise error: ${err.message}`)
            })
        }

        Promise.allSettled(allPromises)
        .then(() => {
            resolve(history)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to fetch list of report templates available to user.
//
function readPatientHistory(worklistEntry) {
    return new Promise((resolve, reject) => {
        let endpoint = '/PatientHistory/' +
            encodeURIComponent(worklistEntry.group) + '/' +
            encodeURIComponent(worklistEntry.issuer) + '/' + 
            encodeURIComponent(worklistEntry.patient_id)
        axios.get(endpoint, {
            baseURL: store.state.webServicesBaseUrl,
            headers: {
                Authorization: 'Bearer '+store.state.keycloak.token
            }
        }).then(response => {
            Vue.$log.debug(response.data)
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

function patientHistoryDocumentUrl(worklistEntry, docId) {
    return store.state.webServicesBaseUrl + '/PatientHistory/documents/' + 
        encodeURIComponent(worklistEntry.group) + '/' +
        encodeURIComponent(worklistEntry.issuer) + '/' + 
        encodeURIComponent(worklistEntry.patient_id) + '/' + 
        encodeURIComponent(docId)
}

// Connects to web services to fetch signature as arraybuffer.
//
function readPatientHistoryDocument(worklistEntry, docId) {
    return new Promise((resolve, reject) => {
        axios.get(patientHistoryDocumentUrl(worklistEntry, docId), {
            headers: {
                Authorization: 'Bearer '+store.state.keycloak.token
            },
            responseType: 'arraybuffer'
        }).then(response => {
            Vue.$log.debug("read document for docId="+docId)
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to update patient history
//
function deletePatientHistoryDocument(worklistEntry, docId) {
    return new Promise((resolve, reject) => {
        axios.delete(
            patientHistoryDocumentUrl(worklistEntry, docId),
            {
                headers: {
                    Authorization: 'Bearer '+store.state.keycloak.token,
                }
            }
        )
        .then(response => {
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to update patient history
//
function deletePatientHistoryNote(worklistEntry, noteId) {
    return new Promise((resolve, reject) => {
        let endpoint = '/PatientHistory/notes/' +
            encodeURIComponent(worklistEntry.group) + '/' +
            encodeURIComponent(worklistEntry.issuer) + '/' + 
            encodeURIComponent(worklistEntry.patient_id) + '/' + 
            encodeURIComponent(noteId)
        axios.delete(
            endpoint,
            {
                baseURL: store.state.webServicesBaseUrl,
                headers: {
                    Authorization: 'Bearer '+store.state.keycloak.token,
                }
            }
        )
        .then(response => {
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to update patient history
//
function updatePatientHistoryDocuments(worklistEntry, document, filename, description, progressCallback) {
    return new Promise((resolve, reject) => {
        let endpoint = '/PatientHistory/documents/' +
            encodeURIComponent(worklistEntry.group) + '/' +
            encodeURIComponent(worklistEntry.issuer) + '/' + 
            encodeURIComponent(worklistEntry.patient_id)
        let formData = new FormData()
        formData.append('description', description)
        formData.append('document', document, filename)
        axios.put(
            endpoint,
            formData,
            {
                baseURL: store.state.webServicesBaseUrl,
                headers: {
                    Authorization: 'Bearer '+store.state.keycloak.token,
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: progressEvent => {
                    progressCallback(progressEvent)
                }
            }
        )
        .then(response => {
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to update patient demographics using IOCM-RS
//
function updatePatientHistoryNotes(worklistEntry, note) {
    return new Promise((resolve, reject) => {
        let endpoint = '/PatientHistory/notes/' +
            encodeURIComponent(worklistEntry.group) + '/' +
            encodeURIComponent(worklistEntry.issuer) + '/' + 
            encodeURIComponent(worklistEntry.patient_id)
        Vue.$log.debug("note="+note)
        const data = {
            note: note
        }
        axios.put(
            endpoint,
            data,
            {
                baseURL: store.state.webServicesBaseUrl,
                headers: {
                    Authorization: 'Bearer '+store.state.keycloak.token
                }
            }
        )
        .then(response => {
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to get dicomweb token.
//
function createDicomwebToken(aet, study_uid) {
    return new Promise((resolve, reject) => {
        let endpoint = store.state.webServicesBaseUrl+'/dicomweb/token'
        const data = {
            aet: aet,
            study_iuid: study_uid
        }
        axios.post(endpoint, data, {
            headers: {
                Authorization: 'Bearer '+store.state.keycloak.token
            }
        })
        .then(response => {
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to get MedDream token.
//
function createMedDreamToken(aet, study_uid, comparison_study_uids) {
    return new Promise((resolve, reject) => {
        let endpoint = store.state.webServicesBaseUrl+'/MedDreamToken'
        var data = {
            aet: aet,
            study_uid: study_uid
        }
        if (Array.isArray(comparison_study_uids)) {
            data['comparison_study_uids'] = JSON.stringify(comparison_study_uids)
        }
        axios.post(endpoint, data, {
            headers: {
                Authorization: 'Bearer '+store.state.keycloak.token
            }
        })
        .then(response => {
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to fetch user setting as JSON object
//
function readSystemSetting(key) {
    return new Promise((resolve, reject) => {
        axios.get('/SystemSettings/setting/' + key , {
            baseURL: store.state.webServicesBaseUrl,
            headers: {
                Authorization: 'Bearer '+store.state.keycloak.token
            }
        }).then(response => {
            Vue.$log.debug('response.data for ['+key+']:')
            Vue.$log.debug(response.data)
            const value = response.data[key]
            resolve(value)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to fetch mgmt report.
//
function readMgmtReport(type, fromDate, toDate, patId, patName, accNum, reportedBy, modalities, groups) {
    return new Promise((resolve, reject) => {
        const mgmtReportUrl = store.state.webServicesBaseUrl + '/MgmtReports/' + 
            encodeURIComponent(type) + '?startDate=' + encodeURIComponent(fromDate) +
            '&endDate=' + encodeURIComponent(toDate) +
            '&patId=' + encodeURIComponent(patId) +
            '&patName=' + encodeURIComponent(patName) +
            '&accNum=' + encodeURIComponent(accNum) +
            '&reportedBy=' + encodeURIComponent(reportedBy) +
            "&modalities=" + encodeURIComponent(JSON.stringify(modalities)) +
            "&groups=" + encodeURIComponent(JSON.stringify(groups))
        axios.get(mgmtReportUrl, {
            headers: {
                Authorization: 'Bearer '+store.state.keycloak.token
            },
        }).then(response => {
            Vue.$log.debug("read ["+type+"] mgmt report")
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to fetch XLSX version of report as arraybuffer.
//
function updateMgmtReport(csvBuffer) {
    return new Promise((resolve, reject) => {
        const mgmtReportUrl = store.state.webServicesBaseUrl + '/MgmtReports/csv2xlsx' 
        let formData = new FormData()
        formData.append('reportCsv', csvBuffer, 'report.csv')
        axios.put(
            mgmtReportUrl,
            formData,
            {
                headers: {
                    Authorization: 'Bearer '+store.state.keycloak.token
                },
                responseType: 'arraybuffer'
            }
        ).then(response => {
            Vue.$log.debug("read xlsx report")
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Gets pixel data for requested frame
//
function readPixelData(instanceMetadata, worklistEntry, seriesUid, instanceUid, frame, quality) {
    return new Promise((resolve, reject) => {
        if (worklistEntry != null) {
            const aet = store.getters.aetForGroup('/'+worklistEntry['group'])
            var pixelDataUrl = store.state.webServicesBaseUrl + '/PixelData/' + aet +
                "/" + encodeURIComponent(worklistEntry.study_uid) +
                "/" + encodeURIComponent(seriesUid) +
                "/" + encodeURIComponent(instanceUid) +
                "/" + encodeURIComponent(frame) + 
                "?quality=" + encodeURIComponent(quality) +
                "&pr=" + encodeURIComponent(dicomweb.getIntegerValue(instanceMetadata, "00280103", 0)) +
                "&ba=" + encodeURIComponent(dicomweb.getIntegerValue(instanceMetadata, "00280100", 8)) +
                "&r=" + encodeURIComponent(dicomweb.getIntegerValue(instanceMetadata, "00280010")) +
                "&c=" + encodeURIComponent(dicomweb.getIntegerValue(instanceMetadata, "00280011")) +
                "&format=zlib"
            axios.get(pixelDataUrl, {
                headers: {
                    Authorization: 'Bearer '+store.state.keycloak.token
                },
                responseType: 'arraybuffer'
            }).then(response => {
                // +TODO+ Handle decompressing.
                //
                resolve(response.data)
            })
            .catch(err => {
                Vue.$log.error("webServices error: "+err.message)
                reject(err)
            })
        }
        else {
            reject(new Error('worklistEntry null'))
        }
    })
}

// Connects to web services to request study is routed (copied) from archive to requested destination
//
function routeStudy(worklistEntry, routeToAet) {
    return new Promise((resolve, reject) => {
        let endpointUrl = store.state.webServicesBaseUrl + '/RouteStudy/' + encodeURIComponent(worklistEntry.study_uid)
        var data = {
            group: '/'+worklistEntry.group,
            routeToAet: routeToAet
        }
        Vue.$log.debug("route study for group=["+data.group+"] studyUid=["+worklistEntry.study_uid+"] routeToAet=["+data.routeToAet+"]")
        axios.put(
            endpointUrl,
            data,
            {
                headers: {
                    Authorization: 'Bearer '+store.state.keycloak.token
                }
            }
        )
        .then(response => {
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to get share address book for current user
//
function readShareAddresses() {
    return new Promise((resolve, reject) => {
        let endpointUrl = store.state.webServicesBaseUrl + '/ShareAddresses'
        axios.get(
            endpointUrl,
            {
                headers: {
                    Authorization: 'Bearer '+store.state.keycloak.token
                }
            }
        )
        .then(response => {
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to add entry to user's share address book
//
function createShareAddress(shareData) {
    return new Promise((resolve, reject) => {
        let endpointUrl = store.state.webServicesBaseUrl + '/ShareAddresses'
        var data = {
            ...shareData
        }
        Vue.$log.debug("create share address")
        axios.post(
            endpointUrl,
            data,
            {
                headers: {
                    Authorization: 'Bearer '+store.state.keycloak.token
                }
            }
        )
        .then(response => {
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to update entry in user's share address book
//
function updateShareAddress(shareData) {
    return new Promise((resolve, reject) => {
        let endpointUrl = store.state.webServicesBaseUrl + '/ShareAddresses/' + encodeURIComponent(shareData['id'])
        var data = {
            ...shareData
        }
        Vue.$log.debug("update share address")
        axios.put(
            endpointUrl,
            data,
            {
                headers: {
                    Authorization: 'Bearer '+store.state.keycloak.token
                }
            }
        )
        .then(response => {
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to request study is routed (copied) from archive to requested destination
//
function shareStudy(worklistEntry, shareAddressId) {
    return new Promise((resolve, reject) => {
        let endpointUrl = store.state.webServicesBaseUrl + '/ShareStudy/' + encodeURIComponent(worklistEntry.study_uid)
        var data = {
            'share_address_id': shareAddressId,
            'group': '/'+worklistEntry.group
        }
        Vue.$log.debug("share study for group=["+data.group+"] studyUid=["+worklistEntry.study_uid+"]")
        axios.put(
            endpointUrl,
            data,
            {
                headers: {
                    Authorization: 'Bearer '+store.state.keycloak.token
                }
            }
        )
        .then(response => {
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

// Connects to web services to request study is routed (copied) from archive to requested destination
//
function readAdminTenants() {
    return new Promise((resolve, reject) => {
        let endpointUrl = store.state.webServicesBaseUrl + '/Tenants/admin/' + encodeURIComponent(store.state.customerId)
        axios.get(
            endpointUrl,
            {
                headers: {
                    Authorization: 'Bearer '+store.state.keycloak.token
                }
            }
        )
        .then(response => {
            resolve(response.data)
        })
        .catch(err => {
            Vue.$log.error("webServices error: "+err.message)
            reject(err)
        })
    })
}

export default {
    getEmptyWorklistEntry,
    getTitleForEntry,
    parseDA,
    parseIsoTimestamp,
    parsePN,
    parseTM,
    reportStatusToVariant,

    createDicomwebToken,
    createMedDreamToken,
    createReport,
    createReportTemplateForGroup,
    createReportTemplateForUser,
    createShareAddress,
    createStudyObject,
    deletePatientHistoryDocument,
    deletePatientHistoryNote,
    diagnostic,
    importStudyObject,
    patientHistoryDocumentUrl,
    readAdminTenants,
    readGroupAeTitle,
    readGroupRouteAets,
    readGroupUsers,
    readKeyObjects,
    readMgmtReport,
    readOrder,
    readOruReport,
    readPatientHistory,
    readPatientHistoryDocument,
    readPixelData,
    readReportList,
    readReport,
    readReportTemplateForGroup,
    readReportTemplateForUser,
    readReportTemplateList,
    readShareAddresses,
    readSignature,
    readSinglePatientWorklist,
    readSingleStudyEntry,
    readSingleStudyWorklist,
    readStudyAssignments,
    readSystemSetting,
    readUserGlobalSettings,
    readUserHistory,
    readUserSetting,
    readWorklist,
    reportUrl,
    restartWorklistRefresh,
    routeStudy,
    shareStudy,
    sleep,
    signatureUrl,
    stopWorklistRefresh,
    updateMgmtReport,
    updatePatientHistoryDocuments,
    updatePatientHistoryNotes,
    updatePatientInfo,
    updateReport,
    updateReportTemplateForGroup,
    updateReportTemplateForUser,
    updateShareAddress,
    updateSignature,
    updateStudyAssignment,
    updateStudyGroup,
    updateStudyLock,
    updateStudyRead,
    updateStudyStat,
    updateStudyUserHistory,
    updateStudyUserHistoryNoPromise,
    updateUserGlobalSettings,
    updateUserSetting
}